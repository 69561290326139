import { AuthModel, UserModel } from "./models";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${API_URL}/login`;
export const GET_ME = `${API_URL}/user/me`;
export const GET_LOGOUT = `${API_URL}/logout`;

export const FORGET_PASSWORD_URL = `${API_URL}/forget-password`;
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

export function requestPassword(email: string) {
  return axios.post(FORGET_PASSWORD_URL, {
    email,
  });
}

export function resetPassword(email: string, token: string, password: string) {
  return axios.post(RESET_PASSWORD_URL, {
    email,
    token,
    password,
  });
}

export function getMe() {
  return axios.get<UserModel>(GET_ME);
}

export function getLogout(token: string) {
  return axios.get(GET_LOGOUT, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(GET_LOGOUT, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}
