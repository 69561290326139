import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { Ability, AbilityBuilder, defineAbility } from "@casl/ability";
import { get, isArray } from "lodash";
import { CASLActions } from "./actions";
import { CASLSubjects } from "./subjects";
import { isFornitoriMode } from "../utils/version";

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export default defineAbility((can, cannot) => {});

export function defineRulesFor(user) {
  const { can, rules } = new AbilityBuilder(Ability);

  if (isFornitoriMode()) {
    can(CASLActions.MANAGE, CASLSubjects.FORNITORI);
  } else {
    const permissions = get(user, "permissions", []);
    if (isArray(permissions)) {
      permissions.forEach((p) => can(p.action, p.name));
    }
  }

  return rules;
}

/*
 * Check if user have at least one permission
 */
export function userHaveAtLeastOnePermission(user, permissions) {
  if (!user || !permissions || isFornitoriMode()) return false;

  const userPermissions = user.permissions.map((x) => x.name);
  const diff = permissions.filter((x) => userPermissions.includes(x));
  return diff.length > 0;
}
