import { FC } from "react";
import { useIntl } from "react-intl";

const Error404: FC = () => {
  const intl = useIntl();

  return (
    <div className="text-center">
      <h1 className="fw-bolder fs-5x text-gray-700 mb-10">{intl.formatMessage({ id: "404.title" })}</h1>

      <div className="fw-bold fs-2 text-gray-400 mb-15">
        {intl.formatMessage({ id: "404.p1" })}
      </div>
    </div>
  );
};

export { Error404 };
