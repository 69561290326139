import clsx from "clsx";
import { FC } from "react";
import { useAuth } from "../../../../app/modules/auth";
import { KTSVG } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { UserEntity } from "../../../../app/entities/user.entity";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar: FC = () => {
  const { config } = useLayout();
  const { currentUser } = useAuth();

  const userEntity = new UserEntity(currentUser);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <span className="symbol symbol-lg-35 symbol-25 symbol-light-warning">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {userEntity.getInitials()}
            </span>
          </span>
        </div>
        <HeaderUserMenu userEntity={userEntity} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
