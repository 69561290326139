import axios from "axios";
import { GanttLegendResults, GanttResults, PressaFermoModel } from "./models";
import { FileExport } from "../common/models";

const API_URL = process.env.REACT_APP_API_URL;

const GET_GANTT = `${API_URL}/gantt/get_gantt`;
const GET_TURNI_LAVORO = `${API_URL}/gantt/turni_lavoro`;
const GET_GANTT_LEGEND = `${API_URL}/gantt/legenda`;
const GET_PRESSE_FERMI = `${API_URL}/gantt/presse-stati-minuto`;
const GET_PRESSE_FERMI_REPORT = `${API_URL}/gantt/presse-stati-minuto/report`;

export function getGantt() {
  return axios.get<GanttResults>(GET_GANTT);
}

export function getTurniLavoro() {
  return axios.get<GanttResults>(GET_TURNI_LAVORO);
}

export function getGanttLegend() {
  return axios.get<GanttLegendResults>(GET_GANTT_LEGEND);
}

export function getPresseFermi(pressaCode: string) {
  return axios.get<PressaFermoModel[]>(`${GET_PRESSE_FERMI}/${pressaCode}`);
}

export function getPresseFermiReport(pressaCode: string) {
  return axios.get<FileExport>(`${GET_PRESSE_FERMI_REPORT}/${pressaCode}`);
}
