/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../../_metronic/layout/core";
import StatoPresseToolbar from "./header/StatoPresseToolbar";
import { Button, Spinner } from "react-bootstrap";
import PressaSelect from "../schedulatore/filters/PressaSelect";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { FermiTable } from "./FermiTable";
import {
  getPresseFermi,
  getPresseFermiReport,
} from "../../../api/dashboard/requests";

import itMessages from "../../../components/selects/localization/it.json";
import moment from "moment";
import LoadingLabel from "../../../components/loading-label";
import { downloadBase64File } from "../../../utils/file";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
loadMessages(itMessages, "it");

const FERMI_PRESSE_REFRESH_MSEC = 60000; //every 60 seconds

const FermiPressePage: FC = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [selectedPressaCode, setSelectedPressaCode] = useState<
    string | undefined
  >(undefined);
  const [records, setRecords] = useState<any[]>([]);
  const intervalref = useRef<number | null>(null);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);

  // Start the interval
  // This will be called when the user clicks on the start button
  const startInterval = () => {
    if (intervalref.current !== null) return;
    intervalref.current = window.setInterval(() => {
      requestPresseFermi();
    }, FERMI_PRESSE_REFRESH_MSEC);
  };

  // Stop the interval
  // This will be called when the user clicks on the stop button
  const stopInterval = () => {
    if (intervalref.current) {
      window.clearInterval(intervalref.current);
      intervalref.current = null;
    }
  };

  const requestPresseFermi = () => {
    if (selectedPressaCode) {
      setLoading(true);
      getPresseFermi(selectedPressaCode)
        .then((response) => {
          setRecords(response.data);
          setLastUpdate(new Date());
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    stopInterval();

    if (!selectedPressaCode) {
      setRecords([]);
    } else {
      requestPresseFermi();
      startInterval();
    }
  }, [selectedPressaCode]);

  useEffect(() => {
    return () => {
      if (intervalref.current !== null) {
        clearInterval(intervalref.current);
      }
    };
  }, []); //eslint-disable-line

  return (
    <LocalizationProvider language="it">
      <PageTitle>{intl.formatMessage({ id: "menu.fermi_presse" })}</PageTitle>
      <StatoPresseToolbar />

      <div className="row g-5 gx-xxl-8">
        <div className="col-12">
          <div className="card">
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column">
                <div>
                  <PressaSelect
                    dataItemName="codice_arduino"
                    onDataLoad={(data) => {
                      if (!selectedPressaCode) {
                        setSelectedPressaCode(data[0].codice_arduino);
                      }
                    }}
                    value={selectedPressaCode}
                    onChange={(param) => setSelectedPressaCode(param)}
                    className="me-3"
                  />
                  {loading && (
                    <Spinner
                      animation="border"
                      style={{ position: "relative", top: 8 }}
                    />
                  )}
                </div>

                <span className="text-muted mt-1 fw-bold fs-7">
                  {lastUpdate && (
                    <>
                      {intl.formatMessage({ id: "common.update" })}{" "}
                      {moment(lastUpdate).format("DD/MM/YYYY HH:mm:ss")}
                      &nbsp;&nbsp;
                      {!loading && (
                        <FontAwesomeIcon
                          icon={faRotateRight}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            requestPresseFermi();
                            startInterval();
                          }}
                          title={intl.formatMessage({ id: "common.refresh" })}
                        />
                      )}
                    </>
                  )}
                </span>
              </div>
              <div className="card-toolbar">
                <Button
                  disabled={loadingReport || !selectedPressaCode}
                  className="btn btn-sm"
                  variant="success"
                  onClick={() => {
                    setLoadingReport(true);
                    getPresseFermiReport(selectedPressaCode!)
                      .then((response) => {
                        if (response.status === 200) {
                          const { filename, content } = response.data;
                          downloadBase64File(filename, content);
                        }
                      })
                      .finally(() => setLoadingReport(false));
                  }}
                >
                  <LoadingLabel
                    loading={loadingReport}
                    title={`${intl.formatMessage({
                      id: "common.export",
                    })} ${intl.formatMessage({ id: "common.pdf" })}`}
                  />
                </Button>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className="card-body py-3">
              <div className="row">
                <div className="col-md-8 col-lg-4">
                  {records.length > 0 ? (
                    <FermiTable records={records} />
                  ) : (
                    <div className="alert alert-warning text-center">
                      {intl.formatMessage({ id: "common.no_results" })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* end::Body */}
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export { FermiPressePage };
