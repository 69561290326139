import axios from "axios";
import { ConfigModel } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_CONFIG_INIT = `${API_URL}/config/setup/init`;

export function getConfigInit() {
  return axios.get<ConfigModel>(GET_CONFIG_INIT, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
