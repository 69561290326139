import { FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { GanttPage } from "../modules/apps/gantt/GanttPage";
import { Can } from "../casl";
import { CASLActions } from "../casl/actions";
import { CASLSubjects } from "../casl/subjects";
import { FermiPressePage } from "../modules/apps/presse-fermi/FermiPressePage";
import { lazyWithRetry } from "../utils/lazyloads";
import { DashboardPage } from "../modules/apps/dashboard/DashboardPage";

const PrivateRoutes = () => {
  const AccountPage = lazyWithRetry(
    () => import("../modules/accounts/AccountPage")
  );

  const UsersPage = lazyWithRetry(
    () => import("../modules/apps/user-management/UsersPage")
  );

  const StampantiPage = lazyWithRetry(
    () => import("../modules/apps/anagrafica/stampanti/StampantiPage")
  );

  const ContestiEtichettePage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/anagrafica/contesti-etichette/ContestiEtichettePage"
      )
  );

  const ProcedurePage = lazyWithRetry(
    () => import("../modules/apps/procedure/ProcedurePage")
  );

  const EtichettePage = lazyWithRetry(
    () => import("../modules/apps/anagrafica/etichette/EtichettePage")
  );

  const BarcodePage = lazyWithRetry(
    () => import("../modules/apps/barcode/BarcodePage")
  );

  const SchedulatorePage = lazyWithRetry(
    () => import("../modules/apps/schedulatore/SchedulatorePage")
  );

  const LiveMonitorPage = lazyWithRetry(
    () => import("../modules/apps/live-monitor/LiveMonitorPage")
  );

  const AttrezzaggioPage = lazyWithRetry(
    () => import("../modules/apps/attrezzaggio/AttrezzaggioPage")
  );

  const CRMSistemaQualitaPage = lazyWithRetry(
    () => import("../modules/apps/crm/sistema-qualita/CRMSistemaQualitaPage")
  );

  const CRMCampionaturePage = lazyWithRetry(
    () => import("../modules/apps/crm/campionature/CRMCampionaturePage")
  );

  const CRMMarchiCampionaturePage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/crm/marchi/campionature/CRMMarchiCampionaturePage"
      )
  );

  const CRMRenderingPage = lazyWithRetry(
    () => import("../modules/apps/crm/rendering/CRMRenderingPage")
  );

  const RisorseElencoPage = lazyWithRetry(
    () => import("../modules/apps/risorse/elenco/RisorseElencoPage")
  );

  const RisorseMovimentiPage = lazyWithRetry(
    () => import("../modules/apps/risorse/movimenti/RisorseMovimentiPage")
  );

  const RisorseAttivitaPage = lazyWithRetry(
    () => import("../modules/apps/risorse/attivita/RisorseAttivitaPage")
  );

  const ViaggiCaricoPage = lazyWithRetry(
    () => import("../modules/apps/viaggi/carico/ViaggiCaricoPage")
  );

  const ViaggiModificaPage = lazyWithRetry(
    () => import("../modules/apps/viaggi/modifica/ViaggiModificaPage")
  );

  const ViaggiRiapriChiudiPage = lazyWithRetry(
    () => import("../modules/apps/viaggi/riapri-chiudi/ViaggiRiapriChiudiPage")
  );

  const ViaggiControlloEvasioniListePage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/viaggi/controllo-evasioni-liste/ViaggiControlloEvasioniListePage"
      )
  );

  const MagazzinoSituazioneArticoliPage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/magazzino/situazione-articoli/MagazzinoSituazioneArticoliPage"
      )
  );

  const MagazzinoEsportaInventarioPage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/magazzino/esporta-inventario/MagazzinoEsportaInventarioPage"
      )
  );

  const MagazzinoImportaInventarioPage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/magazzino/importa-inventario/MagazzinoImportaInventarioPage"
      )
  );

  const MagazzinoVistaOrdClientiApertiPage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/magazzino/vista-ordini-clienti-aperti/MagazzinoVistaOrdClientiApertiPage"
      )
  );

  const MagazzinoVistaPrecaricoPage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/magazzino/vista-precarico/MagazzinoVistaPrecaricoPage"
      )
  );

  const FornitoriMerceInArrivoPage = lazyWithRetry(
    () =>
      import(
        "../modules/apps/fornitori/merce-in-arrivo/FornitoriMerceInArrivoPage"
      )
  );

  const CRMClienteMonclerPage = lazyWithRetry(
    () => import("../modules/apps/crm/cliente-moncler/CRMClienteMonclerPage")
  );

  const CRMClienteAeffePage = lazyWithRetry(
    () => import("../modules/apps/crm/cliente-aeffe/CRMClienteAeffePage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        {/* Pages */}
        <Route path="dashboard" element={<DashboardPage />} />

        <Route
          path="produzione/gantt"
          element={
            <Can I={CASLActions.MANAGE} a={CASLSubjects.PRODUZIONE}>
              <GanttPage />
            </Can>
          }
        />

        <Route
          path="produzione/presse-fermi"
          element={
            <Can I={CASLActions.MANAGE} a={CASLSubjects.PRODUZIONE}>
              <FermiPressePage />
            </Can>
          }
        />

        <Route
          path="produzione/scheduler"
          element={
            <Can I={CASLActions.MANAGE} a={CASLSubjects.SCHEDULER}>
              <SchedulatorePage />
            </Can>
          }
        />

        <Route
          path="produzione/live-monitor"
          element={
            <Can I={CASLActions.MANAGE} a={CASLSubjects.LIVE_MONITOR}>
              <LiveMonitorPage />
            </Can>
          }
        />

        <Route
          path="produzione/attrezzaggio"
          element={
            <Can I={CASLActions.MANAGE} a={CASLSubjects.ATTREZZAGGIO}>
              <AttrezzaggioPage />
            </Can>
          }
        />

        {/* Lazy Modules */}
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="utenti/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.USERS}>
                <UsersPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="anagrafica/stampanti/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.ANAG_STAMPANTI}>
                <StampantiPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="anagrafica/contesti-etichette/*"
          element={
            <SuspensedView>
              <Can
                I={CASLActions.MANAGE}
                a={CASLSubjects.ANAG_CONTESTI_ETICHETTE}
              >
                <ContestiEtichettePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="anagrafica/etichette/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.ANAG_ETICHETTE}>
                <EtichettePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="procedure/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.PROCEDURE}>
                <ProcedurePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="barcode/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.STAMPA_ETICHETTE}>
                <BarcodePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="viaggi/carico/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.VIAGGI}>
                <ViaggiCaricoPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="viaggi/modifica/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.VIAGGI}>
                <ViaggiModificaPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="viaggi/riapri-chiudi/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.VIAGGI}>
                <ViaggiRiapriChiudiPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="viaggi/controllo-evasioni-liste/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.VIAGGI}>
                <ViaggiControlloEvasioniListePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="risorse/elenco/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.RISORSE}>
                <RisorseElencoPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="risorse/movimenti/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.RISORSE}>
                <RisorseMovimentiPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="risorse/attivita/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.RISORSE}>
                <RisorseAttivitaPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="crm/sistema-qualita/elenco/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_QUALITA}>
                <CRMSistemaQualitaPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="crm/campionature/elenco/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_CAMPIONATURE}>
                <CRMCampionaturePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="crm/marchi/campionature/elenco/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_CAMPIONATURE}>
                <CRMMarchiCampionaturePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="crm/cliente-moncler/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_CLIENTE_MONCLER}>
                <CRMClienteMonclerPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="crm/cliente-aeffe/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_CLIENTE_AEFFE}>
                <CRMClienteAeffePage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="crm/rendering/elenco/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_RENDERING}>
                <CRMRenderingPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="magazzino/situazione-articoli/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.MAGAZZINO}>
                <MagazzinoSituazioneArticoliPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="magazzino/esporta-inventario/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.MAGAZZINO}>
                <MagazzinoEsportaInventarioPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="magazzino/importa-inventario/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.MAGAZZINO}>
                <MagazzinoImportaInventarioPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="magazzino/vista-ordini-clienti-aperti/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.MAGAZZINO}>
                <MagazzinoVistaOrdClientiApertiPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="magazzino/vista-precarico/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.MAGAZZINO}>
                <MagazzinoVistaPrecaricoPage />
              </Can>
            </SuspensedView>
          }
        />

        <Route
          path="fornitori/merce-in-arrivo/*"
          element={
            <SuspensedView>
              <Can I={CASLActions.MANAGE} a={CASLSubjects.FORNITORI}>
                <FornitoriMerceInArrivoPage />
              </Can>
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
