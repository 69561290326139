import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useContext } from "react";
import { NotificationsContext } from "./context";

const Notifications = () => {
  const context = useContext(NotificationsContext);

  return (
    <NotificationGroup
      style={{
        right: 10,
        bottom: 10,
        alignItems: "flex-start",
        flexWrap: "wrap-reverse",
        maxWidth: "70%",
      }}
    >
      {context?.notifications.map((n, index) => {
        return (
          <Fade key={`not-group-fade-${index}`}>
            <Notification
              type={{
                style: n.type,
                icon: true,
              }}
              closable={true}
              onClose={() =>
                context.setNotifications(
                  context.notifications.filter((_, i) => i !== index)
                )
              }
            >
              <span>{n.message}</span>
            </Notification>
          </Fade>
        );
      })}
    </NotificationGroup>
  );
};

export default Notifications;
