import { FC } from "react";
import { useIntl } from "react-intl";

const Error500: FC = () => {
  const intl = useIntl();
  return (
    <>
      <h1 className="fw-bolder fs-4x text-gray-700 mb-10">
        {intl.formatMessage({ id: "500.title" })}
      </h1>

      <div className="fw-bold fs-3 text-gray-400 mb-15">
        <span
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "500.p1" }),
          }}
        ></span>
      </div>
    </>
  );
};

export { Error500 };
