/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext, useEffect } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getMe, login } from "../../../api/auth/requests";
import { useAuth } from "../core/Auth";
import APIErrorEntity from "../../../api/error-entity";
import LoadingLabel from "../../../components/loading-label";
import { useIntl } from "react-intl";
import { getConfigInit } from "../../../api/setup/requests";
import { AbilityContext, defineRulesFor } from "../../../casl";

const initialValues = {
  email:
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_LOGIN_USER
      : "",
  password:
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_LOGIN_PASS
      : "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl();

  const loginSchema = Yup.object().shape({
    email: Yup.string().required(
      intl.formatMessage({ id: "common.request_field" })
    ),
    password: Yup.string().required(
      intl.formatMessage({ id: "common.password_required" })
    ),
  });

  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, setConfig, currentUser } = useAuth();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (currentUser) {
      const rules = defineRulesFor(currentUser);
      ability.update(rules);
    }

    return () => setLoading(false);
  }, [currentUser, ability]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(
          values.email ?? "",
          values.password ?? ""
        );

        //Cancella tutta la local storage
        localStorage.clear();
        //

        saveAuth(auth);

        const { data: user } = await getMe();
        setCurrentUser(user);

        const config = await getConfigInit();
        setConfig(config.data);

        resetForm();
      } catch (error) {
        saveAuth(undefined);

        const apiError = new APIErrorEntity(error);

        if (apiError.getStatusCode() === 401) {
          setStatus(intl.formatMessage({ id: "common.invalid_credentials" }));
        } else {
          setStatus(apiError.getFormattedMessage());
        }

        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger text-center">
          <div
            className="alert-text font-weight-bold"
            dangerouslySetInnerHTML={{ __html: formik.status }}
          ></div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          {intl.formatMessage({ id: "common.email_username" })}
        </label>
        <input
          placeholder={intl.formatMessage({ id: "common.email_username" })}
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
          autoFocus
          tabIndex={-1}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              {intl.formatMessage({ id: "common.password" })}
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
              tabIndex={-1}
            >
              {intl.formatMessage({ id: "common.forget_password" })}
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type="password"
          placeholder={intl.formatMessage({ id: "common.password" })}
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
          tabIndex={1}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <LoadingLabel
            title={intl.formatMessage({ id: "common.login" })}
            loading={loading}
          />
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
