import { get, first, upperFirst, isEmpty } from "lodash";

export class UserEntity {
  protected user: any;

  constructor(data: any) {
    this.user = data;
  }

  private _getField(field: string): string {
    if (!this.user) {
      return "";
    }

    return get(this.user, field, "");
  }

  public getInitials(): string {
    if (!this.user) {
      return "";
    }

    let firstName = this.getFirstName();
    firstName = firstName.length > 0 ? firstName.charAt(0).toUpperCase() : "";
    let lastName = this.getLastName();
    lastName = lastName.length > 0 ? lastName.charAt(0).toUpperCase() : "";

    return `${firstName}${lastName}`;
  }

  public getFormattedName() {
    if (!this.user) {
      return "";
    }

    const firstName = get(this.user, "first_name", "");
    const lastName = get(this.user, "last_name", "");
    return `${firstName} ${lastName}`;
  }

  public getFirstName(): string {
    return this._getField("first_name");
  }

  public getLastName(): string {
    return this._getField("last_name");
  }

  public getEmail(): string {
    return this._getField("email");
  }

  public getUsername(): string {
    return this._getField("username");
  }

  public getRoleString(): string | undefined {
    if (this.user) {
      const roles = this.user["user_roles"];

      if (roles && Array.isArray(roles) && !isEmpty(roles)) {
        return upperFirst(first(roles));
      }
    }

    return undefined;
  }
}
