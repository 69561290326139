export enum CASLSubjects {
  USERS = "users",
  ANAG_STAMPANTI = "anag_stampanti",
  ANAG_CONTESTI_ETICHETTE = "anag_contesti_etichette",
  ANAG_ETICHETTE = "anag_etichette",
  PROCEDURE = "procedure",
  STAMPA_ETICHETTE = "stampa_etichette",
  RISORSE = "risorse",
  PRODUZIONE = "produzione",
  GANTT = "gantt",
  LIVE_MONITOR = "live_monitor",
  SCHEDULER = "scheduler",
  CRM_QUALITA = "crm_qualita",
  CRM_CAMPIONATURE = "crm_campionature",
  CRM_RENDERING = "crm_rendering",
  VIAGGI = "viaggi",
  ATTREZZAGGIO = "attrezzaggio",
  MAGAZZINO = "magazzino",
  FORNITORI = "fornitori",
  CRM_CLIENTE_MONCLER = "crm_cliente_moncler",
  CRM_CLIENTE_AEFFE = "crm_cliente_aeffe",
}
