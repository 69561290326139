import {
  SchedulerItem,
  SchedulerItemContent,
  SchedulerItemProps,
} from "@progress/kendo-react-scheduler";
import { get } from "lodash";
import TimelineTurnoItem from "./item-turni";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { StatoPresseContext } from "../StatoPresseContext";

const SERVICE_WIDTH = 24;

const TimelineItem = (props: SchedulerItemProps) => {
  const statoPresseContext = useContext(StatoPresseContext);
  const intl = useIntl();

  if (props.dataItem.turno === true) {
    return <TimelineTurnoItem {...props} />;
  }

  const title = get(props.dataItem, "title", "");
  const description = get(props.dataItem, "description", "");
  const tempoAvvio = parseInt(get(props.dataItem, "tempoAvvio", "0"));

  const backgroundColor = get(props.dataItem, "backgroundColor", "");
  const border = get(props.dataItem, "borderColor", "");
  const textColor = get(props.dataItem, "textColor", "");

  return (
    <SchedulerItem
      {...props}
      style={{
        ...props.style,
        backgroundColor: backgroundColor,
        border: border ? `1px solid ${border}` : "0",
        color: textColor,
      }}
      tail={true}
      onClick={() => statoPresseContext?.setSelectedItem(description)}
    >
      <SchedulerItemContent>
        {tempoAvvio > 0 && (
          <div
            style={{
              display: "inline-block",
              backgroundColor: "grey",
              color: "white",
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              marginRight: 10,
              paddingTop: 3,
              textAlign: "center",
              width: SERVICE_WIDTH,
            }}
            title={intl.formatMessage(
              { id: "stato_presse_timeline.tempo_setup" },
              { val: tempoAvvio }
            )}
          >
            S
          </div>
        )}

        <div
          style={{
            display: "inline-block",
            paddingLeft: tempoAvvio > 0 ? SERVICE_WIDTH : 0,
          }}
        >
          {Array(10)
            .fill(0)
            .map((_, idx) => (
              <span className="me-10" key={`sp-title-${idx}`}>
                {title}
              </span>
            ))}
        </div>
      </SchedulerItemContent>
    </SchedulerItem>
  );
};

export default TimelineItem;
