import React, { useState, useEffect } from "react";
import ButtonGroup, {
  ButtonGroupItem,
} from "../../../../../components/button-group";
import { first, get } from "lodash";
import { useIntl } from "react-intl";
import { StatoPresseTimeline } from "./StatoPresseTimeline";
import moment from "moment";
import { Button, Form, Modal } from "react-bootstrap";
// import { StatoPresseLegend } from "./legend/StatoPresseLegend";

import {
  getGantt,
  getTurniLavoro,
} from "../../../../../api/dashboard/requests";
import { StatoPresseLegend } from "./legend/StatoPresseLegend";

type Props = {
  className: string;
};

const buttonItems: ButtonGroupItem[] = [{ value: 3 }, { value: 7 }];

const TIMELINE_REFRESH_MSEC = 60000; //every 60 seconds

type GanttData = {
  results: any | null;
  turni?: any | null;
  lastUpdate?: Date | undefined;
};

const StatoPresseWrapper: React.FC<Props> = ({ className }) => {
  const [days, setDays] = useState(first(buttonItems)?.value);
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const [showTurni, setShowTurni] = useState<boolean>(false);
  const [data, setData] = useState<GanttData | null>(null);
  const intl = useIntl();

  const requestData = (days: number, visibleTurni: boolean) => {
    const promisesList = [getGantt(), getTurniLavoro()];

    Promise.all(promisesList)
      .then((values) => {
        const results = values[0].data.data;
        const turni = values.length === 2 ? values[1].data.data : [];

        setData({
          results,
          turni,
          lastUpdate: moment().toDate(),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const interval = setInterval(
      () => requestData(days, showTurni),
      TIMELINE_REFRESH_MSEC
    );

    requestData(days, showTurni);

    return () => clearInterval(interval);
  }, []); //eslint-disable-line

  const results = get(data, "results", []);
  const turni = get(data, "turni", []);
  const lastUpdate = get(data, "lastUpdate", undefined);

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="text-muted mt-1 fw-bold fs-7">
              {intl.formatMessage({ id: "common.update" })}{" "}
              {data && data?.lastUpdate && (
                <strong>
                  {moment(data?.lastUpdate).format("DD/MM/YYYY HH:mm:ss")}
                </strong>
              )}
            </span>
          </h3>
          <div className="card-toolbar">
            <Button
              className="me-20 btn btn-sm"
              variant="link"
              onClick={() => setShowLegend(true)}
            >
              {intl.formatMessage({ id: "stato_presse_timeline.legend" })}
            </Button>

            <Form.Check
              className="me-10 btn btn-sm"
              type="checkbox"
              label={intl.formatMessage({ id: "stato_presse_timeline.turni" })}
              onChange={(e) => {
                e.stopPropagation();
                setShowTurni(!showTurni);
              }}
              checked={showTurni}
            />

            <ButtonGroup
              items={buttonItems}
              textSuffix={intl.formatMessage({ id: "common.gg" })}
              value={days}
              onChange={(value) => setDays(value)}
            />
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          <StatoPresseTimeline
            days={days}
            results={results}
            turni={showTurni ? turni : []}
            lastUpdate={lastUpdate}
          />
        </div>
        {/* end::Body */}
      </div>

      <Modal show={showLegend} size="lg" onHide={() => setShowLegend(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl
              .formatMessage({ id: "stato_presse_timeline.legend_title" })
              .toUpperCase()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StatoPresseLegend />
        </Modal.Body>
      </Modal>
    </>
  );
};

export { StatoPresseWrapper };
