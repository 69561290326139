import { FC, useEffect, useState } from "react";
import LoadingView from "../../../../../../components/loading-view";
import { getGanttLegend } from "../../../../../../api/dashboard/requests";
import { GanttLegend } from "../../../../../../api/dashboard/models";
import { get } from "lodash";

type LegendItemProps = {
  title?: string | undefined;
  backgroundColor?: string | undefined;
  borderColor?: string | undefined;
  color?: string | undefined;
};

const LegendItem: FC<LegendItemProps> = ({
  title,
  backgroundColor,
  borderColor,
  color,
}) => {
  return (
    <div
      className="w-100 p-3"
      style={{
        borderWidth: borderColor ? 1 : 0,
        borderColor: borderColor ?? "transparent",
        borderStyle: "solid",
        backgroundColor: backgroundColor ?? "transparent",
      }}
    >
      <h3
        style={{
          fontSize: 16,
          fontWeight: 400,
          marginBottom: 0,
          color: color,
        }}
      >
        {title}
      </h3>
    </div>
  );
};

const StatoPresseLegend = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<GanttLegend[]>([]);

  const requestData = () => {
    getGanttLegend()
      .then((response) => {
        setItems(get(response, "data.data", []));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setItems([]);
      });
  };

  useEffect(() => requestData(), []);

  if (loading) {
    return (
      <div className="row mb-3 mt-4 mb-4">
        <LoadingView />
      </div>
    );
  }

  return (
    <>
      <div className="row mb-3">
        {items.map((item, idx) => (
          <div className="col-4 mb-4" key={`it-legend-${idx}`}>
            <LegendItem
              title={item.descrizione}
              backgroundColor={item.backgroundColor}
              color={item.textColor}
              borderColor={item.borderColor}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export { StatoPresseLegend };
