import { get } from "lodash";

export default class APIErrorEntity {
  protected _error: Error;
  protected _response?: any;

  constructor(error: any) {
    this._error = error;
    this._response = error.response;
  }

  get error(): Error {
    return this._error;
  }

  getStatusCode(): number {
    return parseInt(get(this._response, "status", 0));
  }

  getStatusText(): string {
    return get(this._response, "statusText", "");
  }

  getJSON(): any {
    return get(this._response, "data", null);
  }

  //FIXME: rivedere funzione
  getFormattedMessage(): string | undefined {
    const json = this.getJSON();

    if (json) {
      if (typeof json === "string") {
        return json;
      } else if (typeof json === "object") {
        const errorStrings: string[] = [];

        Object.keys(json).forEach((key) => {
          if (Array.isArray(json[key])) {
            json[key].forEach((item: any) => {
              if (typeof item == "string") {
                errorStrings.push(item);
              }
            });
          } else if (typeof json[key] == "string") {
            errorStrings.push(json[key]);
          }
        });

        return errorStrings.join("<br />");
      }
    }

    return undefined;
  }
}
