import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import { ActivityDrawer, Main, InviteUsers, UpgradePlan } from "../partials";
import { MenuComponent } from "../assets/ts/components";
import Notifications from "../../app/components/notifications";
import { NotificationsContext } from "../../app/components/notifications/context";
import { NotificationModel } from "../../app/components/notifications/model";

const MasterLayout = () => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <NotificationsContext.Provider value={{ notifications, setNotifications }}>
      <PageDataProvider>
        <div className="page d-flex flex-row flex-column-fluid">
          <AsideDefault />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <HeaderWrapper />

            <div
              id="kt_content"
              className="content d-flex flex-column flex-column-fluid"
            >
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
            <Notifications />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <Main />
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </PageDataProvider>
    </NotificationsContext.Provider>
  );
};

export { MasterLayout };
