import {
  SchedulerItem,
  SchedulerItemContent,
  SchedulerItemProps,
} from "@progress/kendo-react-scheduler";
import { get } from "lodash";
import { formatDateFromFormat } from "../../../../../../utils/datetime";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { StatoPresseContext } from "../StatoPresseContext";

const dateTimeFormat = "DD/MM/YY HH:mm";

const TimelineTurnoItem = (props: SchedulerItemProps) => {
  const statoPresseContext = useContext(StatoPresseContext);
  const intl = useIntl();

  const inizio = get(props.dataItem, "start", null);
  const fine = get(props.dataItem, "end", null);

  let description = "";
  if (inizio && fine) {
    description = `${intl.formatMessage({
      id: "stato_presse_timeline.turno",
    })} ${formatDateFromFormat(
      inizio,
      dateTimeFormat
    )} ~ ${formatDateFromFormat(fine, dateTimeFormat)}`;
  }

  return (
    <SchedulerItem
      {...props}
      style={{
        ...props.style,
        backgroundColor: "#f3f3f3",
        color: "#676767",
      }}
      onClick={() => statoPresseContext?.setSelectedItem(description)}
    >
      <SchedulerItemContent>{description}</SchedulerItemContent>
    </SchedulerItem>
  );
};

export default TimelineTurnoItem;
