/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../core";
import { useIntl } from "react-intl";
import moment from "moment";
import { isFornitoriMode } from "../../../app/utils/version";

const Footer: FC = () => {
  const { classes } = useLayout();
  const intl = useIntl();

  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Nav */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a
              href={`mailto:${process.env.REACT_APP_COMPANY_MAIL_CONTACT}`}
              className="menu-link pe-0 pe-2"
            >
              {intl.formatMessage({ id: "common.contact" })}
            </a>
          </li>
        </ul>
        {/* end::Nav */}

        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            {isFornitoriMode() && (
              <small className="me-2">
                {intl.formatMessage({ id: "menu.providers" })}
              </small>
            )}
            {moment().startOf("day").format("Y")}
            {process.env.REACT_APP_GIT_VERSION && (
              <small className="gx-ml-1 gx-text-grey">
                &nbsp;- v{process.env.REACT_APP_GIT_VERSION.substring(0, 7)}
                &nbsp;
              </small>
            )}
            &copy;
          </span>
          <a
            target="_company"
            href={process.env.REACT_APP_COMPANY_URL}
            className="text-gray-800 text-hover-primary"
          >
            {process.env.REACT_APP_COMPANY_NAME}
          </a>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
