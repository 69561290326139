import {
  SchedulerGroup,
  SchedulerResource,
} from "@progress/kendo-react-scheduler";
import moment from "moment";
import { v4 as uuid_v4 } from "uuid";

const RESOURCE_NAME = "Presse";

//Durata intervallo singola cella del gantt
export const GANTT_GRANULARITY_MIN = 15;

export const group: SchedulerGroup = {
  resources: [RESOURCE_NAME],
  orientation: "vertical",
};

export function getResources(
  data: any | null,
  turni: any | null
): SchedulerResource[] {
  let presse: any[] = [];

  if (data) {
    data.forEach((d: any) => {
      const filtered = presse.filter((p) => p.value === d.resourceId);
      if (filtered.length === 0) {
        presse.push({ text: d.resourceId, value: d.resourceId });

        if (turni && turni.length > 0) {
          presse.push({
            text: `${d.resourceId} (turni)`,
            value: `${d.resourceId}_turni`,
          });
        }
      }
    });
  }

  return [
    {
      name: RESOURCE_NAME,
      data: presse,
      field: "resourceId",
      valueField: "value",
      textField: "text",
    },
  ];
}

export function getTimeSlotsFromData(data: any | null, turni: any | null) {
  let items: any[] = [];
  let resources: string[] = [];

  if (data) {
    items = data.map((d: any) => {
      if (!resources.includes(d.resourceId)) {
        resources.push(d.resourceId);

        if (turni && turni.length > 0) {
          resources.push(`${d.resourceId}_turni`);
        }
      }
      return {
        id: `p_${uuid_v4()}`,
        title: d.title,
        description: d.contatore,
        start: new Date(d.start),
        end: new Date(d.end),
        resourceId: d.resourceId,
        textColor: d.textColor,
        backgroundColor: d.backgroundColor,
        borderColor: d.borderColor,
        turno: false,
        tempoAvvio: d.tempo_avvio,
      };
    });
  }

  //Turni di lavoro
  if (turni) {
    resources.forEach((p: string) => {
      turni.forEach((t: any) => {
        items.push({
          id: `t_${uuid_v4()}`,
          resourceId: `${p}_turni`,
          turno: true,
          festivo: t.festivo,
          start: new Date(t.dataora_inizio),
          end: new Date(t.dataora_fine),
        });
      });
    });
  }

  return adjustSlots(items);
}

function approximateSlots(
  date: Date,
  granularityMin: number = GANTT_GRANULARITY_MIN,
  minusSeconds: number = 0
): Date {
  let start = moment(date).set({ second: 0, millisecond: 0 });

  if (start.minute() > 0) {
    const minute = granularityMin * Math.round(start.minute() / granularityMin);
    start.set({ minute });
  }

  return start.add(minusSeconds, "seconds").toDate();
}

function adjustSlots(
  items: any,
  granularityMin: number = GANTT_GRANULARITY_MIN
) {
  for (let i = 0; i < items.length; i++) {
    items[i]["start"] = approximateSlots(items[i]["start"], granularityMin);
    items[i]["end"] = approximateSlots(items[i]["end"], granularityMin, -1);
  }

  return items;
}
