import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../../../api/auth/requests";
import APIErrorEntity from "../../../api/error-entity";
import LoadingLabel from "../../../components/loading-label";
import { useIntl } from "react-intl";

export function ForgotPassword() {
  const intl = useIntl();

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "common.email_format_not_valid" }))
      .required(intl.formatMessage({ id: "common.email_required" })),
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: forgotPasswordSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      setSuccess(undefined);
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { email } }) => {
            setSuccess(true);
            setLoading(false);
            resetForm();
            setStatus(undefined);
          })
          .catch((error) => {
            setSuccess(false);
            setLoading(false);
            setSubmitting(false);
            setStatus(new APIErrorEntity(error).getFormattedMessage());
          });
      }, 1000);
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: "common.forget_password" })}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            {intl.formatMessage({ id: "forget_password.p1" })}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div
              className="alert-text font-weight-bold"
              dangerouslySetInnerHTML={{ __html: formik.status }}
            ></div>
          </div>
        )}

        {success && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">
              {intl.formatMessage({ id: "forget_password.success" })}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {intl.formatMessage({ id: "common.email" })}
          </label>
          <input
            autoFocus
            type="email"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-danger">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={loading}
          >
            <LoadingLabel
              title={intl.formatMessage({ id: "common.reset_password" })}
              loading={loading}
            />
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({ id: "common.login" })}
            </button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
