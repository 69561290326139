import { FC, useState, useEffect } from "react";
import { Scheduler, TimelineView } from "@progress/kendo-react-scheduler";

import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/it/numbers.json";
import currencies from "cldr-numbers-full/main/it/currencies.json";
import caGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/it/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";

import itSchedulerMessages from "./localization/it.json";
import TimelineItem from "./item/item";
import {
  GANTT_GRANULARITY_MIN,
  getResources,
  getTimeSlotsFromData,
  group,
} from "./data/data";
import { StatoPresseContext } from "./StatoPresseContext";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useIntl } from "react-intl";
import { first } from "lodash";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  currencies,
  timeZoneNames
);

loadMessages(itSchedulerMessages, "it-IT");

/**
 * Fa in modo che il gantt inizi a partire dal cursore del giorno e dell'ora attuale.
 */
let offsetAdjusted: boolean = false;

function initialGanttScrollingLeft() {
  const currentTimeItem = first(
    document.getElementsByClassName("k-current-time k-current-time-arrow-down")
  );

  if (currentTimeItem) {
    const container = first(
      document.getElementsByClassName(
        "k-scheduler-layout k-scheduler-layout-flex k-scheduler-timeline-view"
      )
    );

    if (container) {
      const offsetLeft = parseInt(
        window.getComputedStyle(currentTimeItem).left
      );
      container.scrollLeft = Math.max(0, offsetLeft - 100);
      offsetAdjusted = true;
    }
  }
}

type Props = {
  days: number;
  results?: any | null;
  turni?: any | null;
  lastUpdate?: Date | undefined;
};

const editableProps = {
  remove: false,
  drag: false,
  resize: false,
  add: false,
  edit: false,
  select: false,
};

const StatoPresseTimeline: FC<Props> = ({
  days,
  results,
  turni,
  lastUpdate,
}) => {
  const resources = getResources(results, turni);
  const items = getTimeSlotsFromData(results, turni);
  const [selectedItem, setSelectedItem] = useState<any>(undefined);
  const intl = useIntl();

  useEffect(() => {
    setTimeout(() => {
      if (!offsetAdjusted) {
        initialGanttScrollingLeft();
      }
    }, 50);

    return () => {
      offsetAdjusted = false;
    };
  }, [results]);

  if (items.length === 0) {
    return null;
  }

  const dismissModal = () => setSelectedItem(undefined);

  return (
    <StatoPresseContext.Provider value={{ selectedItem, setSelectedItem }}>
      <LocalizationProvider language="it-IT">
        <IntlProvider locale="it">
          <Scheduler
            className="scheduler-presse"
            data={items}
            defaultDate={lastUpdate}
            group={group}
            resources={resources}
            item={TimelineItem}
            header={(props) => <></>}
            footer={(props) => <></>}
            editable={editableProps}
          >
            <TimelineView
              currentTimeMarker={true}
              columnWidth={20}
              slotDuration={60}
              slotDivisions={60 / GANTT_GRANULARITY_MIN}
              numberOfDays={days}
              workDayStart={"00:00:00"}
              workDayEnd={"23:59:59"}
              editable={editableProps}
            />
          </Scheduler>
        </IntlProvider>
      </LocalizationProvider>
      {selectedItem !== undefined && (
        <Dialog onClose={dismissModal}>
          <div
            style={{ width: 300 }}
            dangerouslySetInnerHTML={{ __html: selectedItem }}
          />
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={dismissModal}
            >
              {intl.formatMessage({ id: "common.close" })}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </StatoPresseContext.Provider>
  );
};

export { StatoPresseTimeline };
