import axios from "axios";
import { SchedulerOrderSortInput } from "./models";
import {
  SchedulatoreOrdineModel,
  SchedulatoreOrdiniResults,
  SchedulatorePressaResults,
  SchedulerOrderCreateResult,
  SchedulerOrderUpdateResult,
} from "./models";

const API_URL = process.env.REACT_APP_API_URL;
const GET_SCHEDULATORE_PRESSE = `${API_URL}/scheduler/presse`;
const GET_SCHEDULATORE_ORDINI_LIBERI = `${API_URL}/scheduler/ordini-prod-pressa`;
const GET_SCHEDULATORE_ORDINI_SCHEDULER = `${API_URL}/scheduler/scheduler-pressa`;
const POST_SCHEDULATORE_ORDINE_CREATE = `${API_URL}/scheduler/create`;
const POST_SCHEDULATORE_ORDINE_UPDATE = `${API_URL}/scheduler/update`;
const POST_SCHEDULATORE_ORDINE_DELETE = `${API_URL}/scheduler/delete`;
const POST_SCHEDULATORE_ORDINE_SORT = `${API_URL}/scheduler/sort`;

export function getSchedulatorePresse() {
  return axios.get<SchedulatorePressaResults>(GET_SCHEDULATORE_PRESSE);
}

export function getSchedulatoreOrdiniProduzioneLiberi(pressaId: string) {
  return axios.get<SchedulatoreOrdiniResults>(
    `${GET_SCHEDULATORE_ORDINI_LIBERI}/${pressaId}`
  );
}

export function getSchedulatoreOrdiniSchedulerPressa(pressaId: string) {
  return axios.get<SchedulatoreOrdiniResults>(
    `${GET_SCHEDULATORE_ORDINI_SCHEDULER}/${pressaId}`
  );
}

export function createSchedulerOrder(order: SchedulatoreOrdineModel) {
  return axios.post<SchedulerOrderCreateResult>(
    POST_SCHEDULATORE_ORDINE_CREATE,
    {
      tipocom: order.tipocom,
      annobolla: order.annobolla,
      numerobolla: order.numerobolla,
      numerocom: order.numerocom,
      idriga: order.idriga,
    }
  );
}

export function updateSchedulerOrder(
  order: SchedulatoreOrdineModel,
  params: any
) {
  return axios.post<SchedulerOrderUpdateResult>(
    `${POST_SCHEDULATORE_ORDINE_UPDATE}/${order.id}`,
    params
  );
}

export function deleteSchedulerOrder(taskId: string) {
  return axios.delete<SchedulerOrderUpdateResult>(
    `${POST_SCHEDULATORE_ORDINE_DELETE}/${taskId}`
  );
}

export function sortSchedulerOrders(sorting: SchedulerOrderSortInput[]) {
  return axios.post<SchedulerOrderCreateResult>(POST_SCHEDULATORE_ORDINE_SORT, {
    data: sorting,
  });
}
