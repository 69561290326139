import React from "react";
import { Spinner } from "react-bootstrap";

type Props = {
  loading?: boolean;
  title?: string;
  className?: string;
};

const LoadingLabel: React.FC<Props> = (props) => {
  const { loading, title, className } = props;

  return (
    <span className={`indicator-label ${className}`}>
      {title}
      {loading && (
        <>
          &nbsp;
          <Spinner size="sm" animation="border" />
        </>
      )}
    </span>
  );
};

export default LoadingLabel;
