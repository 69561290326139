import axios from "axios";
import { PresignedURLResponse } from "./models";

const API_URL = process.env.REACT_APP_API_URL;

const GET_PRESIGNED = `${API_URL}/download/presigned/`;

export function getPresignedURL(context: string, id: string, index?: string) {
  return axios.get<PresignedURLResponse>(
    `${GET_PRESIGNED}${context}/${id}/${index}`
  );
}
