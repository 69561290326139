import { lazy } from "react";

/**
 * vedi https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 * https://www.codemzy.com/blog/fix-chunkloaderror-react
 * note:
 * If you need to do multiple module imports on a single route/page and one is successful and the other isn't, the successful one could keep resetting the sessionStorage. It would create that endless refresh loop we talked about earlier.
 * In that situation, I would pass a value as a name to the lazyRetry function to identify the module.
 * @param {*} componentImport
 * @returns
 */
export const lazyWithRetry = (componentImport, name = "") => {
  return lazy(async () => {
    const key = `page${name}-has-been-force-refreshed`;

    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(key) || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(key, "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(key, "true");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
};
