import { FC, useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useIntl } from "react-intl";
import { getSchedulatorePresse } from "../../../../api/schedulatore/requests";
import { SchedulatorePressaModel } from "../../../../api/schedulatore/models";
import { first } from "lodash";

type Props = {
  value?: any;
  dataItemName?: string;
  onChange: (value: any) => void;
  onDataLoad?: (value: any) => void;
  className?: string;
};

const PressaSelect: FC<Props> = ({
  dataItemName = "codice",
  value,
  onChange,
  onDataLoad,
  className,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<SchedulatorePressaModel[]>([]);
  const intl = useIntl();

  const fetchData = () => {
    setLoading(true);

    getSchedulatorePresse()
      .then((response) => {
        setItems(response.data.data);
        onDataLoad && onDataLoad(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setItems([]);
      });
  };

  useEffect(() => fetchData(), []);

  const selectedItem = value
    ? first(items.filter((i: any) => i[dataItemName] === value))
    : null;

  return (
    <DropDownList
      className={className}
      style={{ width: 240 }}
      data={items}
      dataItemKey={dataItemName}
      textField="descrizione"
      onChange={(e) =>
        onChange(
          e.value[dataItemName] !== "" ? e.value[dataItemName] : undefined
        )
      }
      loading={loading}
      value={selectedItem}
      defaultItem={{
        [dataItemName]: "",
        descrizione: intl.formatMessage({ id: "pressa.select" }),
      }}
    />
  );
};

export default PressaSelect;
