import moment from "moment";

export function formatDateTime(value?: string): string {
  return value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "";
}

export function formatDate(value?: string): string {
  return value ? moment(value).format("DD/MM/YYYY") : "";
}

export function formatSmallDate(value?: string): string {
  return value ? moment(value).format("DD/MM/YY") : "";
}

export function formatDateFromFormat(value: string, format: string): string {
  return value ? moment(value).format(format) : "";
}
