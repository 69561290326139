/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { formatDateFromFormat } from "../../../utils/datetime";

type Props = {
  records: any;
};

const FermiTable: FC<Props> = ({ records }) => {
  const intl = useIntl();

  return (
    <table className="table table-sm table-hover table-bordered mt-5 fw-bold gs-0 gy-2">
      <thead>
        <tr>
          <th className="text-dark">
            {intl.formatMessage({ id: "common.from" })}
          </th>
          <th className="text-dark">
            {intl.formatMessage({ id: "common.to" })}
          </th>
          <th className="text-dark">
            {intl.formatMessage({ id: "common.duration" })}
          </th>
        </tr>
      </thead>
      <tbody>
        {records.map((record: any, idx: number) => {
          const bgStyle = {
            backgroundColor: idx % 2 === 0 ? "#f8f9fa" : "#ffffff",
          };
          const colorStyle = record.bgred === 1 ? { color: "#ff0000" } : {};
          return (
            <tr key={`fermi-tr-${idx}`} style={{ ...colorStyle, ...bgStyle }}>
              <td>{formatDateFromFormat(record.start, "DD/MM/YYYY HH:mm")}</td>
              <td>{formatDateFromFormat(record.end, "DD/MM/YYYY HH:mm")}</td>
              <td>
                <strong>{record.fermo}</strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { FermiTable };
