import { Spinner } from "react-bootstrap";

const LoadingView = () => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <Spinner style={{ width: 40, height: 40 }} animation="border" />
    </div>
  );
};

export default LoadingView;
