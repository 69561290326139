/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../../_metronic/layout/core";
import DashboardToolbar from "./header/DashboardToolbar";
import { StatoPresseWrapper } from "./widgets/stato-presse/StatoPresseWrapper";

const GanttPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: "menu.presse_gantt" })}</PageTitle>
      <DashboardToolbar />

      <div className="row g-5 gx-xxl-8">
        <div className="col-12">
          <StatoPresseWrapper className="card-xxl-stretch mb-5 mb-xxl-8" />
        </div>
      </div>
    </>
  );
};

export { GanttPage };
