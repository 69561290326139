import React from "react";

export type ButtonGroupItem = {
  value: any;
  text?: string;
};

type Props = {
  items: ButtonGroupItem[];
  value?: any;
  textSuffix?: string;
  onChange: (value: any) => void;
};

const ButtonGroup: React.FC<Props> = ({
  items,
  value,
  textSuffix,
  onChange,
}) => {
  return (
    <ul className="nav">
      {items.map((item, index) => {
        const cssSel =
          item.value === value
            ? "btn-active btn-active-light-primary active"
            : "";

        const itemText =
          !item.text && textSuffix
            ? `${item.value}${textSuffix}`
            : item.text ?? item.value;
        return (
          <li
            key={`btn-group-k-${item.value}_${index}`}
            className="nav-item cursor-pointer"
          >
            <span
              className={`nav-link btn btn-sm btn-color-muted ${cssSel} fw-bolder px-4 me-1`}
              onClick={(e) => {
                e.stopPropagation();

                if (item.value !== value) {
                  onChange(item.value);
                }
              }}
            >
              {itemText}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default ButtonGroup;
