import { createContext, Dispatch, SetStateAction } from "react";

export type StatoPresseContextType = {
  selectedItem: any | undefined;
  setSelectedItem: Dispatch<SetStateAction<any>>;
};

export const StatoPresseContext = createContext<
  StatoPresseContextType | undefined
>(undefined);
