/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../../_metronic/layout/core";

const DashboardPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: "menu.home" })}</PageTitle>
    </>
  );
};

export { DashboardPage };
