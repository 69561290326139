/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { getAuth, useAuth } from "../../../../app/modules/auth";
import { toAbsoluteUrl } from "../../../helpers";
import { getLogout } from "../../../../app/api/auth/requests";
import { UserEntity } from "../../../../app/entities/user.entity";
import { useIntl } from "react-intl";

type Props = {
  userEntity?: UserEntity;
};

const HeaderUserMenu: FC<Props> = ({ userEntity }) => {
  const { logout } = useAuth();
  const intl = useIntl();

  const signOut = async () => {
    const auth = getAuth();
    if (auth) {
      await getLogout(auth!.access_token);
    }

    logout();
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl("/media/avatars/blank.png")} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                {userEntity?.getFormattedName()}
              </span>
            </div>
            <span className="badge fs-8 px-2 py-1 ms-2 fw-bold text-muted text-hover-primary">
              {userEntity?.getRoleString()}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5 my-1">
        <Link to="/profile/settings" className="menu-link px-5">
          {intl.formatMessage({ id: "menu.impostazioni" })}
        </Link>
      </div>

      <div className="menu-item px-5">
        <a onClick={signOut} className="menu-link px-5 text-danger">
          {intl.formatMessage({ id: "menu.logout" })}
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
