/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { Can, userHaveAtLeastOnePermission } from "../../../../app/casl";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { CASLActions } from "../../../../app/casl/actions";
import { CASLSubjects } from "../../../../app/casl/subjects";
import { useAuth } from "../../../../app/modules/auth";

export function AsideMenuMain() {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "menu.admin" })}
          </span>
        </div>
      </div>

      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/coding/cod004.svg"
        title={intl.formatMessage({ id: "menu.home" })}
      />

      {userHaveAtLeastOnePermission(currentUser, [
        CASLSubjects.PRODUZIONE,
        CASLSubjects.GANTT,
        CASLSubjects.SCHEDULER,
        CASLSubjects.LIVE_MONITOR,
        CASLSubjects.ATTREZZAGGIO,
      ]) && (
        <AsideMenuItemWithSub
          to="/produzione"
          title={intl.formatMessage({ id: "menu.produzione" })}
          icon="/media/icons/duotune/technology/teh006.svg"
        >
          <Can I={CASLActions.MANAGE} a={CASLSubjects.GANTT}>
            <AsideMenuItem
              to="/produzione/gantt"
              icon="/media/icons/duotune/art/art002.svg"
              title={intl.formatMessage({ id: "menu.presse_gantt" })}
              fontIcon="bi-app-indicator"
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.PRODUZIONE}>
            <AsideMenuItem
              to="/produzione/presse-fermi"
              icon="/media/icons/duotune/art/art002.svg"
              title={intl.formatMessage({ id: "menu.fermi_presse" })}
              fontIcon="bi-app-indicator"
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.SCHEDULER}>
            <AsideMenuItem
              to="/produzione/scheduler"
              icon="/media/icons/duotune/technology/teh005.svg"
              title={intl.formatMessage({ id: "menu.scheduler" })}
              fontIcon="bi-app-indicator"
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.LIVE_MONITOR}>
            <AsideMenuItem
              to="/produzione/live-monitor"
              icon="/media/icons/duotune/electronics/elc005.svg"
              title={intl.formatMessage({ id: "menu.live_monitor" })}
              fontIcon="bi-app-indicator"
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.ATTREZZAGGIO}>
            <AsideMenuItem
              to="/produzione/attrezzaggio"
              icon="/media/icons/duotune/technology/teh009.svg"
              title={intl.formatMessage({ id: "menu.attrezzaggio" })}
              fontIcon="bi-app-indicator"
            />
          </Can>
        </AsideMenuItemWithSub>
      )}

      {userHaveAtLeastOnePermission(currentUser, [
        CASLSubjects.ANAG_STAMPANTI,
        CASLSubjects.ANAG_CONTESTI_ETICHETTE,
        CASLSubjects.ANAG_ETICHETTE,
      ]) && (
        <AsideMenuItemWithSub
          to="/anagrafica"
          title={intl.formatMessage({ id: "menu.anagrafica" })}
          icon="/media/icons/duotune/communication/com012.svg"
        >
          <Can I={CASLActions.MANAGE} a={CASLSubjects.ANAG_STAMPANTI}>
            <AsideMenuItem
              to="/anagrafica/stampanti"
              title={intl.formatMessage({ id: "menu.stampanti" })}
              hasBullet={true}
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.ANAG_CONTESTI_ETICHETTE}>
            <AsideMenuItem
              to="/anagrafica/contesti-etichette"
              title={intl.formatMessage({ id: "menu.contesti_etichette" })}
              hasBullet={true}
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.ANAG_ETICHETTE}>
            <AsideMenuItem
              to="/anagrafica/etichette"
              title={intl.formatMessage({ id: "menu.etichette" })}
              hasBullet={true}
            />
          </Can>
        </AsideMenuItemWithSub>
      )}

      <Can I={CASLActions.MANAGE} a={CASLSubjects.PROCEDURE}>
        <AsideMenuItem
          to="/procedure"
          icon="/media/icons/duotune/communication/com001.svg"
          title={intl.formatMessage({ id: "menu.procedure" })}
        />
      </Can>

      <Can I={CASLActions.MANAGE} a={CASLSubjects.STAMPA_ETICHETTE}>
        <AsideMenuItem
          to="/barcode"
          icon="/media/icons/duotune/coding/cod010.svg"
          title={intl.formatMessage({ id: "menu.barcode" })}
        />
      </Can>

      <Can I={CASLActions.MANAGE} a={CASLSubjects.VIAGGI}>
        <AsideMenuItemWithSub
          to="/viaggi"
          title={intl.formatMessage({ id: "menu.viaggi" })}
          icon="/media/icons/duotune/maps/map003.svg"
        >
          <AsideMenuItem
            to="/viaggi/carico"
            title={intl.formatMessage({ id: "menu.viaggi-carico" })}
            hasBullet={true}
          />
          <AsideMenuItem
            to="/viaggi/modifica"
            title={intl.formatMessage({ id: "menu.viaggi-modifica" })}
            hasBullet={true}
          />
          <AsideMenuItem
            to="/viaggi/riapri-chiudi"
            title={intl.formatMessage({ id: "menu.viaggi-riapri-chiudi" })}
            hasBullet={true}
          />
          <AsideMenuItem
            to="/viaggi/controllo-evasioni-liste"
            title={intl.formatMessage({
              id: "menu.viaggi-controllo-evasioni-liste",
            })}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </Can>

      <Can I={CASLActions.MANAGE} a={CASLSubjects.RISORSE}>
        <AsideMenuItemWithSub
          to="/risorse"
          title={intl.formatMessage({ id: "menu.risorse" })}
          icon="/media/icons/duotune/abstract/abs027.svg"
        >
          <AsideMenuItem
            to="/risorse/elenco"
            title={intl.formatMessage({ id: "menu.risorse-elenco" })}
            hasBullet={true}
          />
          <AsideMenuItem
            to="/risorse/movimenti"
            title={intl.formatMessage({ id: "menu.risorse-movimenti" })}
            hasBullet={true}
          />

          <AsideMenuItem
            to="/risorse/attivita"
            title={intl.formatMessage({ id: "menu.risorse-attivita" })}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </Can>

      {userHaveAtLeastOnePermission(currentUser, [
        CASLSubjects.CRM_QUALITA,
        CASLSubjects.CRM_CAMPIONATURE,
        CASLSubjects.CRM_RENDERING,
        CASLSubjects.CRM_CLIENTE_MONCLER,
        CASLSubjects.CRM_CLIENTE_AEFFE,
      ]) && (
        <AsideMenuItemWithSub
          to="/crm"
          title={intl.formatMessage({ id: "menu.crm" })}
          icon="/media/icons/duotune/abstract/abs014.svg"
        >
          <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_QUALITA}>
            <AsideMenuItem
              to="/crm/sistema-qualita/elenco"
              title={intl.formatMessage({ id: "menu.sistema-qualita" })}
              hasBullet={true}
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_CAMPIONATURE}>
            <AsideMenuItem
              to="/crm/campionature/elenco"
              title={intl.formatMessage({ id: "menu.campionature" })}
              hasBullet={true}
            />

            <AsideMenuItem
              to="/crm/marchi/campionature/elenco"
              title={intl.formatMessage({ id: "menu.marchi_campionature" })}
              hasBullet={true}
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_RENDERING}>
            <AsideMenuItem
              to="/crm/rendering/elenco"
              title={intl.formatMessage({ id: "menu.rendering" })}
              hasBullet={true}
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_CLIENTE_MONCLER}>
            <AsideMenuItem
              to="/crm/cliente-moncler"
              title={intl.formatMessage({ id: "menu.cliente-moncler" })}
              hasBullet={true}
            />
          </Can>

          <Can I={CASLActions.MANAGE} a={CASLSubjects.CRM_CLIENTE_AEFFE}>
            <AsideMenuItemWithSub
              to="/crm/cliente-aeffe"
              title={intl.formatMessage({ id: "menu.cliente-aeffe" })}
              hasBullet={true}
            >
              <AsideMenuItem
                to="/crm/cliente-aeffe/generale"
                title={intl.formatMessage({ id: "crm.cliente_aeffe_general" })}
                hasBullet={true}
              />

              <AsideMenuItem
                to="/crm/cliente-aeffe/destinazioni"
                title={intl.formatMessage({
                  id: "crm.cliente_aeffe_destinazioni",
                })}
                hasBullet={true}
              />

              <AsideMenuItem
                to="/crm/cliente-aeffe/call"
                title={intl.formatMessage({ id: "crm.cliente_aeffe_call" })}
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
          </Can>
        </AsideMenuItemWithSub>
      )}

      <Can I={CASLActions.MANAGE} a={CASLSubjects.MAGAZZINO}>
        <AsideMenuItemWithSub
          to="/magazzino"
          title={intl.formatMessage({ id: "menu.magazzino" })}
          icon="/media/icons/duotune/abstract/abs027.svg"
        >
          <AsideMenuItem
            to="/magazzino/situazione-articoli"
            title={intl.formatMessage({ id: "magazzino.situazione-articoli" })}
            hasBullet={true}
          />

          <AsideMenuItem
            to="/magazzino/esporta-inventario"
            title={intl.formatMessage({ id: "magazzino.esporta-inventario" })}
            hasBullet={true}
          />

          <AsideMenuItem
            to="/magazzino/importa-inventario"
            title={intl.formatMessage({ id: "magazzino.importa-inventario" })}
            hasBullet={true}
          />

          <AsideMenuItem
            to="/magazzino/vista-precarico"
            title={intl.formatMessage({ id: "magazzino.vista-precarico" })}
            hasBullet={true}
          />

          <AsideMenuItem
            to="/magazzino/vista-ordini-clienti-aperti"
            title={intl.formatMessage({
              id: "magazzino.vista-ordini-clienti-aperti",
            })}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </Can>

      <Can I={CASLActions.MANAGE} a={CASLSubjects.FORNITORI}>
        <AsideMenuItem
          to="/fornitori/merce-in-arrivo"
          icon="/media/icons/duotune/communication/com014.svg"
          title={intl.formatMessage({ id: "menu.providers" })}
        />
      </Can>

      <Can I={CASLActions.MANAGE} a={CASLSubjects.USERS}>
        <AsideMenuItem
          to="/utenti"
          icon="/media/icons/duotune/communication/com006.svg"
          title={intl.formatMessage({ id: "menu.users" })}
        />
      </Can>
    </>
  );
}
