import { createContext, Dispatch, SetStateAction } from "react";
import { NotificationModel } from "./model";

export type NotificationsContextType = {
  notifications: NotificationModel[];
  setNotifications: Dispatch<SetStateAction<NotificationModel[]>>;
};

export const NotificationsContext = createContext<
  NotificationsContextType | undefined
>(undefined);
